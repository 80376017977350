import React from 'react'
import styled, { css } from 'styled-components'
import Layout from '../layouts'
import axios from 'axios'
import Mailchimp from '../components/Mailchimp'

import { PostTitle, PostText, BaseInput } from '../components/Common.js'

import SEO from '../components/SEO.js'

import config from '../../config'

const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://faf-planner.now.sh/'

console.log({ API_URL })

//XXX SO this is risky because we need to keep the ui/api synced
const REASONS = [
  { label: 'Steady income', uid: 'steadyIncom' },
  { label: 'Making connections', uid: 'connections' },
  { label: 'Large clients', uid: 'largeClients' },
  { label: 'Learning new skills', uid: 'newSkills' },
  { label: 'Quit day job', uid: 'quitJob' },
  { label: 'Safety net for current job', uid: 'saftyNet' },
  { label: 'Quick cash', uid: 'quickCash' },
  { label: 'Extra time on your hands', uid: 'extraTime' },
  { label: 'Career path switch', uid: 'careerSwitch' },
  { label: 'Stay at home parent', uid: 'homeParent' },
  { label: 'Choose your own work', uid: 'chooseWork' },
  { label: 'Working remotely', uid: 'workRemote' },
  { label: 'Help community', uid: 'helpCommunity' },
  { label: 'springboard for bigger goal', uid: 'springboard' },
  { label: 'choose your own schedule', uid: 'chooseSchedule' },
  { label: "Can't land a job", uid: 'landJob' },
  { label: 'Control social circle', uid: 'socialCircle' },
  { label: 'Be your own boss', uid: 'ownBoss' },
  { label: 'Build your portfolio', uid: 'buildPortfolio' },
  { label: 'Everyone else is doing it', uid: 'trend' },
  { label: 'Fulfill passion', uid: 'passion' },
  { label: 'Lack of local opportunities', uid: 'noLocalOpportunities' },
  { label: 'College alternative', uid: 'noCollege' },
  { label: 'Medical Reasons', uid: 'medical' },
  { label: 'Become a nomad', uid: 'nomad' },
].map((reasons, i) => {
  return {
    ...reasons,
    // id: 'reason-' + i,
    checked: false,
  }
})
const report = {
  title: 'Steady income',
  summary: `INTJs radiate self-confidence and an aura of mystery, and
their insightful observations.`,
  pros: [
    `<b>NTJs radiate self-confidence</b> - and an aura of mystery`,

    `At times it will seem
that INTJs are bent on deconstructing and rebuilding every
idea and system`,
  ],
  cons: `Rules, limitations and traditions are anathema to the INTJ
personality type`,
  nextSteps: [
    `NTJs radiate self-confidence and an aura of mystery, and
their insightful observations`,

    `At times it will seem
that INTJs`,
    `Anyone who doesn’t have the talent to keep up with INTJs’
processes`,
  ],
}

const Temp = () => (
  <body
    className
    style={{
      backgroundColor: '#f6f6f6',
      fontFamily: 'sans-serif',
      WebkitFontSmoothing: 'antialiased',
      fontSize: 14,
      lineHeight: '1.4',
      margin: 0,
      padding: 0,
      msTextSizeAdjust: '100%',
      WebkitTextSizeAdjust: '100%',
    }}
  >
    <table
      border={0}
      cellPadding={0}
      cellSpacing={0}
      className="body"
      style={{
        borderCollapse: 'separate',
        msoTableLspace: '0pt',
        msoTableRspace: '0pt',
        width: '100%',
        backgroundColor: '#f6f6f6',
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              fontFamily: 'sans-serif',
              fontSize: 14,
              verticalAlign: 'top',
            }}
          >
            &nbsp;
          </td>
          <td
            className="container"
            style={{
              fontFamily: 'sans-serif',
              fontSize: 14,
              verticalAlign: 'top',
              display: 'block',
              margin: '0 auto',
              maxWidth: 580,
              padding: 10,
              width: 580,
            }}
          >
            <div
              className="content"
              style={{
                boxSizing: 'border-box',
                display: 'block',
                margin: '0 auto',
                maxWidth: 580,
                padding: 10,
              }}
            >
              <span
                className="preheader"
                style={{
                  color: 'transparent',
                  display: 'none',
                  height: 0,
                  maxHeight: 0,
                  maxWidth: 0,
                  opacity: 0,
                  overflow: 'hidden',
                  msoHide: 'all',
                  visibility: 'hidden',
                  width: 0,
                }}
              >
                This is preheader text. Some clients will show this text as a
                preview.
              </span>
              <table
                className="main"
                style={{
                  borderCollapse: 'separate',
                  msoTableLspace: '0pt',
                  msoTableRspace: '0pt',
                  width: '100%',
                  background: '#ffffff',
                  borderRadius: 3,
                }}
              >
                <tbody>
                  <tr>
                    <td
                      className="wrapper"
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: 14,
                        verticalAlign: 'top',
                        boxSizing: 'border-box',
                        padding: 20,
                      }}
                    >
                      <table
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        style={{
                          borderCollapse: 'separate',
                          msoTableLspace: '0pt',
                          msoTableRspace: '0pt',
                          width: '100%',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontFamily: 'sans-serif',
                                fontSize: 14,
                                verticalAlign: 'top',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'sans-serif',
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  margin: 0,
                                  marginBottom: 15,
                                }}
                              >
                                Hi there,
                              </p>
                              <p
                                style={{
                                  fontFamily: 'sans-serif',
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  margin: 0,
                                  marginBottom: 15,
                                }}
                              >
                                Sometimes you just want to send a simple HTML
                                email with a simple design and clear call to
                                action. This is it.
                              </p>
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                className="btn btn-primary"
                                style={{
                                  borderCollapse: 'separate',
                                  msoTableLspace: '0pt',
                                  msoTableRspace: '0pt',
                                  width: '100%',
                                  boxSizing: 'border-box',
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontFamily: 'sans-serif',
                                        fontSize: 14,
                                        verticalAlign: 'top',
                                        paddingBottom: 15,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{
                                          borderCollapse: 'separate',
                                          msoTableLspace: '0pt',
                                          msoTableRspace: '0pt',
                                          width: 'auto',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                fontFamily: 'sans-serif',
                                                fontSize: 14,
                                                verticalAlign: 'top',
                                                backgroundColor: '#3498db',
                                                borderRadius: 5,
                                                textAlign: 'center',
                                              }}
                                            >
                                              {' '}
                                              <a
                                                href="http://htmlemail.io"
                                                target="_blank"
                                                style={{
                                                  display: 'inline-block',
                                                  color: '#ffffff',
                                                  backgroundColor: '#3498db',
                                                  border: 'solid 1px #3498db',
                                                  borderRadius: 5,
                                                  boxSizing: 'border-box',
                                                  cursor: 'pointer',
                                                  textDecoration: 'none',
                                                  fontSize: 14,
                                                  fontWeight: 'bold',
                                                  margin: 0,
                                                  padding: '12px 25px',
                                                  textTransform: 'capitalize',
                                                  borderColor: '#3498db',
                                                }}
                                              >
                                                Call To Action
                                              </a>{' '}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p
                                style={{
                                  fontFamily: 'sans-serif',
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  margin: 0,
                                  marginBottom: 15,
                                }}
                              >
                                This is a really simple email template. Its sole
                                purpose is to get the recipient to click the
                                button with no distractions.
                              </p>
                              <p
                                style={{
                                  fontFamily: 'sans-serif',
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  margin: 0,
                                  marginBottom: 15,
                                }}
                              >
                                Good luck! Hope it works.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                className="footer"
                style={{
                  clear: 'both',
                  marginTop: 10,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <table
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  style={{
                    borderCollapse: 'separate',
                    msoTableLspace: '0pt',
                    msoTableRspace: '0pt',
                    width: '100%',
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        className="content-block"
                        style={{
                          fontFamily: 'sans-serif',
                          verticalAlign: 'top',
                          paddingBottom: 10,
                          paddingTop: 10,
                          fontSize: 12,
                          color: '#999999',
                          textAlign: 'center',
                        }}
                      >
                        <span
                          className="apple-link"
                          style={{
                            color: '#999999',
                            fontSize: 12,
                            textAlign: 'center',
                          }}
                        >
                          Company Inc, 3 Abbey Road, San Francisco CA 94102
                        </span>
                        <br /> Don't like these emails?{' '}
                        <a
                          href="http://i.imgur.com/CScmqnj.gif"
                          style={{
                            textDecoration: 'underline',
                            color: '#999999',
                            fontSize: 12,
                            textAlign: 'center',
                          }}
                        >
                          Unsubscribe
                        </a>
                        .
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="content-block powered-by"
                        style={{
                          fontFamily: 'sans-serif',
                          verticalAlign: 'top',
                          paddingBottom: 10,
                          paddingTop: 10,
                          fontSize: 12,
                          color: '#999999',
                          textAlign: 'center',
                        }}
                      >
                        Powered by{' '}
                        <a
                          href="http://htmlemail.io"
                          style={{
                            color: '#999999',
                            fontSize: 12,
                            textAlign: 'center',
                            textDecoration: 'none',
                          }}
                        >
                          HTMLemail
                        </a>
                        .
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
          <td
            style={{
              fontFamily: 'sans-serif',
              fontSize: 14,
              verticalAlign: 'top',
            }}
          >
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </body>
)

export default class Planner extends React.Component {
  state = {
    reasons: REASONS,
    showReport: false,
  }

  handleFetchReport = async ({ to = '', reasons = [] } = {}) => {
    try {
      const reportResponse = await axios.post(API_URL, {
        to,
        reasons,
      })

      console.log(reportResponse)
    } catch (error) {
      console.error(error)
    }
  }

  handleCheckReason(target) {
    const { name, checked } = target
    const { reasons } = this.state

    const updated = reasons.map((reason, i) => {
      if (reason.uid === name) {
        reason.checked = checked
      }

      return reason
    })

    this.setState({
      reasons: updated,
    })
  }

  handleSubmitResponse(err, data) {
    console.log('now show the report!')
  }

  handleGetReport(email) {
    console.log('get report')

    const selectedReasons = this.state.reasons
      .filter(o => o.checked)
      .map(o => o.uid)

    console.log(selectedReasons)

    this.handleFetchReport({ to: email, reasons: selectedReasons })

    this.setState({
      showReport: true,
    })
  }

  hasMetLimit = reasons => this.state.reasons.filter(o => o.checked).length >= 5

  renderReasonSelector() {
    const { reasons } = this.state

    const limitMet = this.hasMetLimit()

    const isInactive = _checked => limitMet && _checked

    const renderReasons = reasons.map(({ label, checked, uid }, i) => (
      <ReasonItem key={i} inactive={isInactive(!checked)}>
        <ReasonLabel
          htmlFor={uid}
          checked={checked}
          inactive={isInactive(!checked)}
        >
          <ReasonCheckbox
            id={uid}
            name={uid}
            type="checkbox"
            checked={checked}
            onChange={({ target }) => {
              if (limitMet && target.checked) return

              this.handleCheckReason(target)
            }}
          />
          <span>{label}</span>
        </ReasonLabel>
      </ReasonItem>
    ))

    return (
      <>
        {this.renderContent()}

        <ol>{renderReasons}</ol>

        {limitMet && (
          <p>
            Great! Now that you have selected 5 priorities, just put in your
            email to get your report. If you want to change change any of your
            selections, just click on the one you would like to remove and
            select another.
          </p>
        )}

        <Mailchimp
          afterSubmit={({ email }) => {
            this.handleGetReport(email)
          }}
          messages={{
            buttonText: 'Get Custom Report!',
            inputPlaceholder: 'Example: darryl@example.com',
          }}
          bypassAlreadySubscribed={true}
        />
      </>
    )
  }

  renderContent() {
    return (
      <PostText>
        <p>The first step is often the hardest</p>
        <p>
          The question is uaully which direction should I step in. I'll attempt
          to help point you in the right direction by establishing som
          priorities
        </p>
        <p>
          I'm no guru at setting priorities but, Warren buffet is! We'll use his
          5/25 rules. Watch the video to understand better!
        </p>
        <iframe
          title="video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/gkhtYs22bLI"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <p>
          I helped you out by picky 25 of the commonly used reason why prople
          freelance.
        </p>
        <p>
          I'll then take a step futer and email you a report of the priorities
          you selected.
        </p>

        <p>
          (keep in mind you can only pick five, look through the entire list
          before you choose!
        </p>
      </PostText>
    )
  }

  renderReport() {
    return (
      <div>
        <h2>Done! Check your email.</h2>
        <p>
          After seeing your report, and still want to dive deeper into the first
          steps of freelance
        </p>
        <p>
          I'll suggest taking a look at the Fluff Free-Preelancing guide to get
          you going!
        </p>
        <p>
          Have some specific question? [Shoot an email] or reach out on
          [twitter]
        </p>
        {/* <ReasonReport
                    title={report.title}
                    summary={report.summary}
                    pros={report.pros}
                    cons={report.cons} //going to be the block quote?
                    nextSteps={report.nextSteps}
                /> */}
      </div>
    )
  }

  render() {
    // const { showReport } = this.state

    return (
      <Layout title={config.siteTitle + ' | Freelance Planner'}>
        <PostTitle style={{ color: '#78abbe', marginBottom: 30 }}>
          Freelance Planner. Comming soon :)
        </PostTitle>
      </Layout>
    )

    // return (
    //   <Layout title={config.siteTitle + ' | Freelance Planner'}>
    //     <PostTitle style={{ color: '#78abbe', marginBottom: 30 }}>
    //       Freelance Planner. Comming soon :)
    //     </PostTitle>

    //     <Temp />

    //     {showReport ? this.renderReport() : this.renderReasonSelector()}

    //     <SEO />
    //   </Layout>
    // )
  }
}

// Set here the ID of the home page.
// export const pageQuery = graphql`
//     query freelancePlanner {
//         allWordpressPost {
//             edges {
//                 node {
//                     id
//                     title
//                     excerpt
//                     slug
//                     date(formatString: "MMMM DD, YYYY")
//                     better_featured_image {
//                         media_details {
//                             sizes {
//                                 large {
//                                     source_url
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
`

export const ContactInput = BaseInput.extend`
  margin-bottom: 15px;
  margin-right: 0;
`

export const TextArea = BaseInput.withComponent('textarea')

export const MessageArea = TextArea.extend`
  margin-bottom: 15px;
  margin-right: 0;
  height: 200px;
  resize: none;
`

export const Success = styled.div`
  opacity: 1;
  padding: 20px;
  background: #c8e4bd;
  margin-bottom: 20px;
  border: 1px solid #8aaa7d;
`

export const ReasonItem = styled.li`
  margin-bottom: 30px;
  transition: 0.3s;

  ${props =>
    props.inactive &&
    css`
      opacity: 0.3;
      display: none;
    `};
`
export const ReasonLabel = styled.label`
    font-size: ${p => (p.inactive ? 'inherit' : '1.5em')} ;
    padding: 4px 8px;
    border: 1px solid #78aabf;
    color: #78aabf;
    border-radius: 3px;
    transition: 0.3s;
    ${props =>
      props.checked &&
      css`
        background: #78aabf;
        color: white;
      `};

    /* ${props =>
      props.inactive &&
      css`
        font-size: inherit;
      `}; */
`

export const ReasonCheckbox = styled.input`
  margin-right: 5px;
  position: absolute;
  left: -100px;
  opacity: 0;
  transition: 0.3s;
`
